.entry_classification_container-fluid {
  overflow-y: auto;
  height: 80vh;
  margin-top: 1%;
}

.entry_classification_container {
  width: 90%;
  margin: auto;
}

.entry_classification_row.ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.entry_classification_card {
  margin-top: 10px;
  width: 100%;
}

.entry_classification_card.ant-card .ant-card-body {
  padding: 12px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Make sure this is higher than other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}
