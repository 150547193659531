.ForgotPassword {
  width: 335px;
  margin: 176px auto 0;
  display: flex;
  flex-direction: column;
}
img.imgLogoIwakiFG {
  width: 114px;
  height: 28px;
}
.ant-select.optionLanguageFG.ant-select-single.ant-select-show-arrow {
  width: 96px;
  height: 38px;
}
.titleForgot {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
.textTitleHeader {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: 800;
  line-height: 27.5px;
  text-align: center;
  color: #0f172a;
}
.textTitleBody {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  color: #64748b;
}
.FormForgot {
  margin-top: 24px;
}
.formVerifyPassword .ant-form-item {
  margin-bottom: 12px;
}
button.ant-btn.ant-btn-primary.btnSubmitForgot {
  width: 100%;
  height: 48px;
  border-radius: 16px;
  border: 1px;
  background: #0c4da2;
}
.spanBtnSubmit {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: center;
}
span.ant-input-affix-wrapper.inputEmail {
  width: 100%;
  height: 48px;
  padding: 14px;
  border-radius: 16px;
  border: 1px solid #0ea2e0;
}

span.ant-input-affix-wrapper.inputEmail > input.ant-input::placeholder {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #64748b;
}
.btnBatch {
  width: 100%;
  display: flex;
  justify-content: center;
}
.spanBack {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 22.4px;
  text-align: center;
  color: #394b76;
}
.containerReceiver {
  display: flex;
  justify-content: flex-start;
}
.snapReceive {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.resendText {
  color: #64748b;
}
.resendLink {
  color: #0c4da2;
}
