.check_classification_container-fluid {
  overflow-y: scroll;
  height: 80vh;
}

.check_classification_container {
  width: 90%;
  margin: auto;
}

.check_classification_row.ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.check_classification_card {
  margin-top: 10px;
  width: 100%;

  &[data-color="true"] {
    .ant-card-cover {
      border: 5px solid red !important;
    }
  }

  &[data-check_qa="true"] {
    .ant-card-cover {
      border: 5px solid yellow !important;
    }
  }
}

.check_classification_card.ant-card .ant-card-body {
  padding: 12px;
}
