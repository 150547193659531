.ant-drawer-left > .ant-drawer-content-wrapper {
  width: 100% !important;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 6px 0 16px 0 rgba(0, 0, 0, 0.08),
    3px 0 6px -4px rgba(0, 0, 0, 0.12), 9px 0 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-drawer .ant-drawer-header {
  padding: 4px 14px;
}

.ant-drawer .ant-drawer-body {
  padding: 0px;
}

.bg-not-qualified {
  background-color: #ff38389e;
}

.bg-checkLogic {
  background-color: #ffffcc;
}

.ant-modal.modal-submit-lc .ant-modal-content {
  padding: 8px 24px;
  width: max-content;
}

.modal-submit-lc {
  & .row-content-modal-submit-lc {
    padding: 0% 0% 5%;
    display: grid;
    justify-content: center;
    align-items: center;

    & .text-content-modal-submit-lc {
      font-size: 18px;
      font-weight: 500;
      color: #25355b;
      text-align: center;
    }
  }
  & .row-btn-modal-submit-lc {
    display: flex;
    justify-content: center;
    column-gap: 2ch;

    & .btn-modal-submit-lc {
      width: 40%;
      font-weight: 600;
    }
  }
}

.ant-tooltip-inner {
  width: max-content;
}
