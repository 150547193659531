.modal-user-form-item .ant-row.ant-form-item-row {
  display: inline-block;
  width: 100%;
}

.modal-user-form-item.ant-form-item .ant-form-item-label {
  text-align: start;
}

.modal-user-form-item.ant-form-item {
  margin-bottom: 12px;
}
