.content1 {
  transform: translateX(0);
  transition: transform 0.5s ease;
  width: 100%;
  will-change: margin-left;
}

.content1.collapsed {
  transform: translateX(80px);
}

.ant-message
  .ant-message-notice-wrapper
  .ant-message-custom-content
  > .anticon {
  display: none !important;
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-content {
  padding: 0px 4px;
  border-radius: 16px;
  /* background-color: #ffffffe8; */
  background-color: #fff;
  color: #203b72;
  font-size: 14px;
  font-weight: 600;
  width: 82%;
  border: 1.5px solid #5e7ac4;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.menu-admin.ant-menu-horizontal {
  border-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}

.header-page-admin-user {
  height: max-content;
}

.row-page-admin-user {
  margin: 14px 24px 0 24px;
}

.header-page-admin-user-col-14 {
  display: flex;
  justify-content: right;
  align-items: center;
}

.header-page-admin-user-button {
  height: 40px;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.tabs-page-admin-user {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.tabs-page-admin-user .ant-tabs-tab {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.tabs-page-admin-user .ant-tabs-tab.ant-tabs-tab-active {
  background: #f4f3fa;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.tabs-page-admin-user .ant-tabs-nav {
  width: 100% !important;
}

.tabs-page-admin-user.ant-tabs-top > .ant-tabs-nav {
  margin: 0 !important;
}

.tabs-page-admin-user.ant-tabs-top.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
}

.tabs-page-admin-user.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.tabs-page-admin-user.ant-tabs-left
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin: 0;
}

.drawer-page-admin-user .MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  width: 600px;
}

.MuiDrawer-root.MuiDrawer-modal.drawer-page-admin-user {
  background-color: #8080806b;
}
